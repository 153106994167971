import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';

const VideoPlayer = (props) => {

  const { url, windowDimensions, setVideoCanPlay } = props;
  const [aspectRatio, setAspectRatio] = useState(0.5625);
  const { windowWidth, windowHeight } = windowDimensions;

  const player = useRef();

  useEffect(() => {
    if (url) {
      if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
        const video = document.createElement('video');

        const getAspectRatio = () => {
          setAspectRatio(video.videoHeight / video.videoWidth);
          console.log(video.videoHeight / video.videoWidth)
        }


        console.log(video)

        video.addEventListener('load', getAspectRatio);
        video.src = url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : `${cockpitUrl}${url}`;
      }
    }
  }, [setVideoCanPlay, aspectRatio, url]);

  if (url) {
    return (
      <div
        className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}`}
        style={{
          width: windowHeight / windowWidth <= aspectRatio ? windowWidth + 'px' : windowHeight / aspectRatio + 'px',
          height: windowHeight / windowWidth <= aspectRatio ? windowWidth * aspectRatio + 'px' : windowHeight + 'px'
        }}
      >
        <div className="video__wrapper">
          <ReactPlayer
            ref={player}
            url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
            className="video-player__player"
            playsInline={true}
            playing={true}
            volume={0}
            muted={true}
            height={'100%'}
            width={'100%'}
            loop={true}
            allow="autoplay"
            onCanPlay={(e) => {
              setVideoCanPlay(true);
              if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
                if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                  setAspectRatio(player.current.getInternalPlayer().videoHeight / player.current.getInternalPlayer().videoWidth);
                }
              }
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default VideoPlayer;