import { useState, useRef, useEffect } from 'react';
import { cockpitUrl } from '../utils/cockpit';

const Video = (props) => {

  const { url, setVideoCanPlay } = props;
  const [aspectRatio, setAspectRatio] = useState(0.5625);

  const player = useRef();

  useEffect(() => {

    const playVideoOnClick = () => {
      if (player.current) {
        player.current.play()
          .then(() => {
            setVideoCanPlay(true);
            window.removeEventListener('click', playVideoOnClick);
            window.removeEventListener('touchstart', playVideoOnClick);
          })
        .catch((e) => {
        });
      }
    }

    if (url) {
      if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
        const video = document.createElement('video');

        const getAspectRatio = () => {
          setAspectRatio(video.videoHeight / video.videoWidth);
          console.log(video.videoHeight / video.videoWidth)
        }

        if (player.current) {
          player.current.play()
            .then(() => setVideoCanPlay(true))
            .catch((e) => {
              window.addEventListener('click', playVideoOnClick);
              window.addEventListener('touchstart', playVideoOnClick);
            });
        }

        video.addEventListener('load', getAspectRatio);
        video.src = url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : `${cockpitUrl}${url}`;
      }
    }
  }, [setVideoCanPlay, aspectRatio, url]);

  if (url) {
    return (
      <div className="video__wrapper--self-hosted">
        <video
          ref={player}
          src={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
          className="video"
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          allow="autoplay"
          onCanPlay={(e) => {
            setVideoCanPlay(true);
            if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
              if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                setAspectRatio(player.current.getInternalPlayer().videoHeight / player.current.getInternalPlayer().videoWidth);
              }
            }
          }}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default Video;