const Logo = (props) => {

  const { windowDimensions, displacement, logoIsVisible, setLogoIsVisible, setIsInitialised } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  const handleClickText = () => {
    setLogoIsVisible(true);
    setIsInitialised(true);
  }

  return (
    <div
      className={`slogan__wrapper${logoIsVisible === true ? ' logo-is-visible' : ''}`}
      style={{
        opacity: logoIsVisible === false ? 1 : 0,
        pointerEvents: logoIsVisible === true ? 'none' : 'auto'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${windowWidth} ${windowWidth < 768 ? windowHeight - 90 : windowHeight - 99}`}
        width={windowWidth - 40}
        height={windowWidth < 768 ? windowHeight - 90 : windowHeight - 99}
        className="slogan"
      >
        <filter id="distortionFilter">
          <feTurbulence
            type="turbulence"
            baseFrequency="0.006"
            numOctaves="1"
            seed="1"
            stitchTiles="stitch"
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
            result="noise"
          />
          <feDisplacementMap
            ref={displacement}
            in="SourceGraphic"
            in2="noise"
            scale="0"
            xChannelSelector="R"
            yChannelSelector="B"
            x="-50%"
            y="0%"
            width="200%"
            height="200%"
            filterUnits="userSpaceOnUse"
          />
        </filter>
        <g filter="url(#distortionFilter)">
          <g className="text" style={{
            textTransform: 'uppercase'
          }}>
            <text
              x="4%"
              y={`${100 / 6 * 0 + 4}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >Le</text>
            <text
              x="30%"
              y={`${100 / 6 * 1}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >Deshonneur,</text>
            <text
              x="60%"
              y={`${100 / 6 * 2}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >C’est</text>
            <text
              // x="55%"
              y={`${100 / 6 * 3}%`}
              x={`${windowWidth < 768 ? 0 : 20}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >Une</text>
            <text
              x={`${windowWidth < 768 ? 45 : 55}%`}
              y={`${100 / 6 * 3}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >Jouissance</text>
            <text
              x="40%"
              y={`${100 / 6 * 4}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >de</text>
            <text
              x={`${windowWidth < 768 ? 75 : 90}%`}
              y={`${100 / 6 * 5}%`}
              className="slogan__text font--centaur"
              onClick={handleClickText}
            >fille</text>
          </g>
        </g>
      </svg>
    </div>
  )
};

export default Logo;