const Logo = (props) => {

  const { logoIsVisible, setLogoIsVisible, displacementLogo } = props;

  return (
    <div
      className={`logo__wrapper${logoIsVisible === true ? ' logo-is-visible' : ''}`}
      style={{
        opacity: logoIsVisible === true ? 1 : 0,
        pointerEvents: logoIsVisible === false ? 'none' : 'auto'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 1633.13 1283.46`}
        width={1633.13}
        height={1283.46}
        className="logo"
      >
        <filter id="distortionFilterLogo">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.008"
            numOctaves="1"
            seed="4"
            stitchTiles="stitch"
            x="0%"
            y="0%"
            width="120%"
            height="120%"
            result="noise"
          />
          <feDisplacementMap
            ref={displacementLogo}
            in="SourceGraphic"
            in2="noise"
            scale="0"
            xChannelSelector="R"
            yChannelSelector="B"
            x="0%"
            y="0%"
            width="200%"
            height="200%"
            filterUnits="userSpaceOnUse"
          />
        </filter>
        <g filter="url(#distortionFilterLogo)">
          <g className="logo-image">
            <path
              onClick={() => setLogoIsVisible(false)}
              d="M685,856.8c-37.9-46.73-57.11-119.09-57.11-215.07S647.08,473.38,685,426.65c22.16-27.33,48.06-46,78.87-56.7l-3.09.42c-62.21,8.53-114.68,32.91-156,72.46C552.22,493.22,525.55,560,525.55,641.32v.81c0,81.32,26.67,148.1,79.26,198.5,41.27,39.54,93.74,63.92,156,72.45l3.09.43C733,902.77,707.14,884.14,685,856.8"
            />
            <path
              onClick={() => setLogoIsVisible(false)}
              d="M948.16,856.8c37.89-46.73,57.11-119.09,57.11-215.07s-19.22-168.35-57.11-215.08c-22.17-27.33-48.07-46-78.88-56.7l3.1.42c62.21,8.53,114.68,32.91,155.95,72.46,52.59,50.39,79.25,117.18,79.25,198.49v.81c0,81.32-26.66,148.1-79.25,198.5-41.27,39.54-93.74,63.92-155.95,72.45l-3.1.43c30.81-10.74,56.71-29.37,78.88-56.71"
            />
          </g>
        </g>
      </svg>
    </div>
  )
};

export default Logo;