import { useState, useEffect } from 'react';
import { fetchCockpitData, cockpitUrl } from './utils/cockpit';
import { AnimatePresence, motion } from 'framer-motion';
import VideoPlayer from './components/VideoPlayer';
import Video from './components/Video';
import LogoType from './components/LogoType';
import HoldingPageContent from './components/HoldingPageContent';
import * as _ from 'underscore';
import LazyloadImage from './components/LazyloadImage';

const App = () => {

  const [siteOptions, setSiteOptions] = useState({});
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const { windowWidth, windowHeight } = windowDimensions;
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);

  useEffect(() => {
    fetchCockpitData('siteOptions', setSiteOptions, false);
  }, []);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();

    const handleWindowResizeThrottled = _.throttle(handleWindowResize, 120);

    window.addEventListener('resize', handleWindowResizeThrottled);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div
      className={`main${fontsLoaded === true ? ' fonts-loaded' : ''}`}
      style={{
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      {
        windowWidth >= windowHeight ?
          (siteOptions.backgroundSelector === 'image' || videoCanPlay === false) && siteOptions.backgroundImage &&
          <LazyloadImage image={siteOptions.backgroundImage} isBackgroundImage={true} w={windowWidth * window.devicePixelRatio} h={windowHeight * window.devicePixelRatio} />
        :
          (siteOptions.backgroundSelectorMobile === 'image' || videoCanPlay === false) && siteOptions.backgroundImageMobile &&
            <LazyloadImage image={siteOptions.backgroundImageMobile} isBackgroundImage={true} w={windowWidth * window.devicePixelRatio} h={windowHeight * window.devicePixelRatio} />
      }
      {
        (
          (windowWidth >= windowHeight && siteOptions.backgroundSelector !== 'image')
          ||
          (windowWidth < windowHeight && siteOptions.backgroundSelectorMobile !== 'image')
        ) &&
        <div className="background-video__wrapper"
          style={{
            opacity: videoCanPlay === true ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out'
          }}
        >
          {
            windowWidth >= windowHeight ?
              siteOptions.backgroundSelector === 'video' ?
                <Video url={cockpitUrl + '/' + siteOptions.backgroundVideo} windowDimensions={windowDimensions} setVideoCanPlay={setVideoCanPlay} />
                :
                <VideoPlayer url={siteOptions.backgroundVimeo} windowDimensions={windowDimensions} setVideoCanPlay={setVideoCanPlay} />
              :
              siteOptions.backgroundSelectorMobile === 'video' ?
                <Video url={cockpitUrl + '/' + siteOptions.backgroundVideoMobile} windowDimensions={windowDimensions} setVideoCanPlay={setVideoCanPlay} />
                :
                <VideoPlayer url={siteOptions.backgroundVimeoMobile} windowDimensions={windowDimensions} setVideoCanPlay={setVideoCanPlay} />
          }
        </div>
      }
      <AnimatePresence>
        {
          fontsLoaded === true &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className="wrapper"
            style={{ height: '100%', width: '100%' }}
          >
            <h1 className="title--hidden font--centaur">Odilia</h1>
            <div className="title">
              <LogoType />
            </div>
            <HoldingPageContent
              windowDimensions={windowDimensions}
            />
            <footer>
              <p className="footer__text font--circular">
                {
                  siteOptions.email &&
                  <a
                    className="footer__link"
                    href={`mailto:${siteOptions.email}`}
                    rel="noreferrer"
                    target="_blank"
                  >{siteOptions.email}</a>
                }
                {
                  siteOptions.instagram &&
                  <a
                    className="footer__link"
                    href={`https://instagram.com/${siteOptions.instagram.replace('@', '').replace('https://instagram.com/', '').replace('instagram.com/', '')}`}
                    rel="noreferrer"
                    target="_blank"
                  >@{siteOptions.instagram.replace('@', '').replace('https://instagram.com/', '').replace('instagram.com/', '')}</a>
                }
              </p>
            </footer>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}

export default App;