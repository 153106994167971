import { useRef, useState, useEffect } from 'react';
import Logo from './Logo';
import Slogan from './Slogan';

const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

const HoldingPageContent = (props) => {
  const [logoIsVisible, setLogoIsVisible] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);

  const displacement = useRef();
  const displacementLogo = useRef();

  useEffect(() => {
    let raf;
    let amount = 0;
    let amountLogo = 0;
    const startTime = new Date().getTime();
    let totalDuration = 2000;
    if (logoIsVisible === false) {
      totalDuration = 1600;
    }
    const maxDisplacement = window.innerWidth / 4;
    const maxDisplacementLogo = 200;

    const transitionLoop = () => {
      const now = new Date().getTime();
      const timeElapsed = now - startTime;

      if (logoIsVisible === true) {
        amount = map(timeElapsed, 0, totalDuration * 1, 0, maxDisplacement);
        amountLogo = map(timeElapsed, 0, totalDuration * 1, maxDisplacementLogo, 0);
      } else {
        amount = map(timeElapsed, 0, totalDuration * 1, maxDisplacement, 0);
        amountLogo = map(timeElapsed, 0, totalDuration * 1, 0, maxDisplacementLogo);
      }

      if (timeElapsed >= totalDuration) {
        cancelAnimationFrame(raf);
        amountLogo = 0;
        amount = 0;
        displacement.current.setAttribute('scale', 0);
        displacementLogo.current.setAttribute('scale', 0);
      } else {
        raf = requestAnimationFrame(transitionLoop);
      }
      if (displacement.current) {
        displacement.current.setAttribute('scale', amount);
      }
      if (displacementLogo.current) {
        displacementLogo.current.setAttribute('scale', amountLogo);
      }
    }

    if (isInitialised === true) {
      transitionLoop();
    }

  }, [logoIsVisible, isInitialised]);

  return (
    <div className="content">

      <Slogan
        {...props}
        displacement={displacement}
        logoIsVisible={logoIsVisible}
        setLogoIsVisible={setLogoIsVisible}
        setIsInitialised={setIsInitialised}
      />
      <Logo
        {...props}
        displacementLogo={displacementLogo}
        logoIsVisible={logoIsVisible}
        setLogoIsVisible={setLogoIsVisible}
      />
    </div>
  );
}

export default HoldingPageContent;