const LogoType = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 14.77" width="150" height="14.77" className="logotype">
    <path d="M1.64,6.53C1.64,2.86,4.19.77,6.92.77c3.45,0,6.29,3.31,6.29,7.43S10.52,14,8.18,14c-3.49,0-6.54-3.47-6.54-7.42M0,7.37a7.35,7.35,0,0,0,7.23,7.37,7.43,7.43,0,0,0,7.64-7.42A7.31,7.31,0,0,0,7.62,0,7.61,7.61,0,0,0,0,7.37" />
    <path d="M67.86,14.65v-.42h-.34c-1.39-.12-1.68-.43-1.85-.93a31.75,31.75,0,0,1-.33-5.74c0-2.19,0-2.91.11-4.74,0-1.49.15-1.92,2.2-2h.08V.39H61.62V.84h.09c2,.06,2.15.49,2.2,2C64,4.65,64,5.37,64,7.56a30.83,30.83,0,0,1-.34,5.74c-.16.5-.45.81-1.84.93H61.5v.42Z" />
    <path d="M118.84,14.65v-.42h-.34c-1.39-.12-1.68-.43-1.84-.93a30.83,30.83,0,0,1-.34-5.74c0-2.19,0-2.91.11-4.74,0-1.49.16-1.92,2.2-2h.09V.39h-6.11V.84h.08c2,.06,2.16.49,2.2,2,.07,1.83.11,2.55.11,4.74a31.75,31.75,0,0,1-.33,5.74c-.17.5-.46.81-1.85.93h-.34v.42Z" />
    <path d="M96.22,11.6a2.28,2.28,0,0,1-1,1.47,9,9,0,0,1-4.19.79c-2.19,0-2.3-.11-2.48-.27s-.29-.86-.29-2.59,0-4,.09-5.84l.06-2.37c0-1.49.15-1.92,2.2-2h.08V.36l-6.25,0V.84h.09c2,.06,2.16.49,2.2,2,.07,1.83.11,2.55.11,4.74a31.75,31.75,0,0,1-.33,5.74c-.17.5-.46.81-1.85.93h-.34v.42h11.4a15.17,15.17,0,0,0,.83-3Z" />
    <path d="M138.44,8.8l.6-1.3,1.33-2.81c.14-.24.53-1.06.83-1.62.23.38,1.11,2,1.28,2.39l1,2c.14.22.49,1,.61,1.31Zm10.12,4.89a32.54,32.54,0,0,1-2.63-5l-2.85-6.2a23.08,23.08,0,0,1-.9-2.32h-.47L137.4,9l-.1.2-.08.18-.26.53a16.85,16.85,0,0,1-2.39,3.86,3,3,0,0,1-1.25.52v.32h0v.06h5.13v-.36c-1.91-.22-2.16-.6-2.16-1.06,0-.29.14-.58.66-1.6l.91-1.84.09-.18h0l0-.07h6.39v0h0l.06.13.94,2.08a6.22,6.22,0,0,1,.61,1.76c0,.49-.78.63-1.36.72v.4H150v-.47a2.09,2.09,0,0,1-1.44-.49" />
    <path d="M37,14.27H35.87c-1.31-.12-1.59-.43-1.76-.92a26.09,26.09,0,0,1-.33-5.13c0-1.19,0-2.59.06-3.81V4.19a10.55,10.55,0,0,1,.27-2.3c.14-.4.34-.67,1.12-.83a6.6,6.6,0,0,1,1-.09h.24l.41,0a6.55,6.55,0,0,1,6.67,6.63c0,3.09-1.64,6.69-6.49,6.69M37.2.44H29.85V1h.08c2.05.05,2.16.49,2.2,2,.07,1.83.11,2.55.11,4.73a31.81,31.81,0,0,1-.33,5.75c-.17.5-.46.81-1.85.92h-.34v.43h7.4c5.05,0,8.32-3.16,8.32-7.21,0-4.45-3.94-7.1-8.24-7.12" />
  </svg>
);

export default LogoType;